<template>
  <main class="home">
    <section class="header">
      <h1>Start building</h1>
      <p>Which type of competition are you hosting?</p>
    </section>

    <section class="cards">
      <div
        @mouseover="option = 'oneOffEvent'"
        @mouseleave="option = ''"
        class="build-card"
        :class="{ selected: option == 'oneOffEvent' }"
        @click="toggleView('oneOffEvent')"
      >
        <div class="left">
          <SingleEventBuilderIcon :filled="option == 'oneOffEvent'" />
        </div>
        <div class="right">
          <h4>One-off event</h4>
          <p>One day or weekend event. <br />As many days as needed.</p>
        </div>

        <div class="footer">Start building</div>
      </div>
      <div
        @mouseover="option = 'tournament'"
        @mouseleave="option = ''"
        class="build-card"
        :class="{ selected: option == 'tournament' }"
        @click="toggleView('tournament')"
      >
        <div class="left">
          <TournamentBuilderIcon :filled="option == 'tournament'" />
        </div>
        <div class="right">
          <h4>Tour</h4>
          <p>Local, National, International. <br />Any type of tour.</p>
        </div>
        <div class="footer">Start building</div>
      </div>
      <div
        class="build-card"
        @mouseover="option = 'weekly'"
        @mouseleave="option = ''"
        :class="{ selected: option == 'weekly' }"
        @click="toggleView('weekly')"
      >
        <div class="left">
          <LeagueBuilderIcon :filled="option == 'weekly'" />
        </div>
        <div class="right">
          <h4>League</h4>
          <p>
            Once a week or twice a week.<br />
            The weekly wizard does it.
          </p>
        </div>
        <div class="footer">Start building</div>
      </div>
    </section>
  </main>
</template>

<script>
import SingleEventBuilderIcon from "@/assets/icons/SingleEventBuilderIcon";
import TournamentBuilderIcon from "@/assets/icons/TournamentBuilderIcon";
import LeagueBuilderIcon from "@/assets/icons/LeagueBuilderIcon";

export default {
  name: "BuilderHome",
  data() {
    return { option: null };
  },
  components: {
    SingleEventBuilderIcon,
    TournamentBuilderIcon,
    LeagueBuilderIcon,
  },
  computed: {
    isMobile() {
      return this.$store.getters.deviceWidth < 764;
    },
    wizardType() {
      return this.$store.state.builder.startedWizardType;
    },
  },
  methods: {
    goTo() {
      if (this.option != this.wizardType) {
        this.$store.dispatch("resetBuilderState");
      }
      if (this.option == "weekly") {
        this.$store.dispatch("setwizardType", "weekly");
        this.$router.push({ name: "leaguesmandatory" });
      } else if (this.option == "tournament") {
        this.$store.dispatch("setwizardType", "tournament");
        this.$router.push({ name: "tournamentdetails" });
      } else if (this.option == "oneOffEvent") {
        this.$store.dispatch("setwizardType", "oneOffEvent");
        this.$router.push({ name: "singleeventmandatory" });
      }
    },
    toggleView(option) {
      this.goTo();
    },
  },
  beforeMount() {
    if (
      this.$store.state.builder.wizardType == "" ||
      !this.$store.state.builder.tour.divisions
    ) {
      this.$store.dispatch("resetBuilderState");
    }
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.build-card {
  margin-top: 24px;
  background-color: white;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  box-shadow: 0 0 0 1px $sleet;
  padding: 20px 24px;
  position: relative;
  .footer {
    display: none;
  }

  .left {
    width: 35%;
  }
  .right {
    width: 65%;
  }

  svg {
    height: 44px;
    width: auto;
  }

  h4 {
    margin-bottom: 12px;
    font-size: 18px;
  }

  p {
    margin: 0;
    font-size: 14px;
  }
}
.home {
  padding: 0 15px;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 32px;
    @include Gilroy-Bold;
    text-align: center;
    margin-bottom: 16px;
  }
  p {
    font-size: 14px;
    text-align: center;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .buttons {
    margin-top: 70px;
  }
  .cards {
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    .build-card {
      width: 32%;
      transition: all ease 0.3s;
      flex-direction: column;
      padding: 0 15px;

      .footer {
        z-index: 0;
        display: flex;
        height: 48px;
        max-height: 0;
        width: 100%;
        transition: all ease 0.3s;
        background-color: $twilight;
        font-size: 18px;
        color: white;
        @include Gilroy-Bold;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 0 0px $twilight;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        width: 100%;
        letter-spacing: 0.5px;
        overflow: hidden;
      }

      .left {
        padding-top: 51px;
      }
      .right {
        padding-bottom: 51px;
      }

      .left,
      .right {
        width: 100%;
        text-align: center;
      }
      svg {
        height: 87px;
      }
      p {
        font-size: 14px;
      }
      h4 {
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 16px;
      }
    }
  }
  .header {
    margin-top: 100px;
    h1 {
      font-size: 64px;
      margin: 0;
    }
    p {
      margin-top: 24px;
      font-size: 18px;
    }
  }
}
@media (min-width: 1200px) {
  .header {
    margin-top: 48px;
    p {
      margin-bottom: 0;
    }
  }
  .cards {
    margin-top: 72px;
    .build-card {
      width: 320px;
      padding: 0;
      &:hover {
        cursor: pointer;
        .footer {
          display: flex;
          max-height: 48px;
          box-shadow: 0 0 0 1px $twilight;
          background-color: $twilightshade;
        }
      }
    }
  }
}
</style>
