<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90"
    height="87"
    viewBox="0 0 90 87"
    :class="{ colored: filled }"
  >
    <g transform="translate(-0.2)">
      <line class="a" x2="90" transform="translate(0.2 85)" />
      <g transform="translate(50.88 38.259)">
        <line class="b" y2="22" transform="translate(14.32 23.741)" />
        <line class="b" y2="16" transform="translate(14.32 5.741)" />
        <rect
          class="c"
          width="28"
          height="9"
          rx="4.5"
          transform="translate(0.32 23.741)"
        />
        <path
          class="c d-1"
          d="M522.858,391.658H509.211L504.093,374h23.883Z"
          transform="translate(-502.018 -368.114)"
        />
        <path
          class="a"
          d="M523.476,370.414a4.241,4.241,0,1,0-8.476,0"
          transform="translate(-505.221 -366)"
        />
      </g>
      <g class="d" transform="translate(-433.783 -314)">
        <path class="h" d="M460.539,314h-11.3L445,400.818h19.777Z" />
        <path
          class="i"
          d="M 453.04736328125 318 L 449.1999816894531 396.817626953125 L 460.5767211914062 396.817626953125 L 456.7293701171875 318 L 453.04736328125 318 M 449.2378540039062 314 L 460.5388793945312 314 L 464.7767333984375 400.817626953125 L 444.9999694824219 400.817626953125 L 449.2378540039062 314 Z"
        />
      </g>
      <path
        class="e"
        d="M449,406.331l14.126-16.186-12.714-16.186,11.3-16.186L450.413,346"
        transform="translate(-434.958 -322.456)"
      />
      <path
        class="f"
        d="M517.333,341.7a4.226,4.226,0,1,0,4-5.542V326"
        transform="translate(-455.026 -317.171)"
      />
      <path
        class="g"
        d="M510.457,321.886V316L437,324.829V336.6Z"
        transform="translate(-431.434 -314.529)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: ["filled"],
  name: "SingleEventBuilderIcon",
};
</script>

<style lang="scss" scoped>
.a,
.d,
.e,
.f {
  fill: none;
}
.a,
.b,
.c,
.e,
.f {
  stroke: #2d323c;
  stroke-width: 4px;
}
.a,
.b,
.c,
.d {
  stroke-miterlimit: 10;
}
.b {
  fill: #00e1a5;
}
.c {
  transition: all ease 0.3s;
  fill: #05b5bc;
}
.e {
  stroke-miterlimit: 1;
}
.f {
  stroke-linejoin: round;
}
.g,
.i {
  fill: #2d323c;
}
.h,
.i {
  stroke: none;
}
@media (min-width: 1200px) {
  .c {
    transition: all ease 0.3s;
    fill: #fff;
  }
  .colored {
    .c {
      transition: all ease 0.7s;
      fill: #05b5bc;

      &.d-1 {
        transition-delay: 0.1s;
      }
      &.d-2 {
        transition-delay: 0.15s;
      }
      &.d-3 {
        transition-delay: 0.2s;
      }
      &.d-4 {
        transition-delay: 0.25s;
      }
      &.d-5 {
        transition-delay: 0.3s;
      }
      &.d-6 {
        transition-delay: 0.35s;
      }
    }
  }
}
</style>